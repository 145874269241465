<app-nav-bar [svg]="true"></app-nav-bar>
<app-bread-crumb title="Servicios"></app-bread-crumb>

<div class="about_area svg_bg pt-50">
    <div class="container">
    <div class="row wow fadeInLeft">
      <div class="col-lg-4 col-md-4">
        <ul class="list-group">
          <li *ngFor="let f of SERVICES" class="pl-0 pr-0 list-group-item d-flex align-items-center">
            <i class="fa fa-check-square-o text-primary mr-2"></i>
            {{f.left | titlecase}}
          </li>
        </ul>
      </div>
      <div class="col-lg-4 col-md-4">
        <ul class="list-group">
          <li *ngFor="let f of SERVICES" class="pl-0 pr-0 list-group-item d-flex align-items-center">
            <i class="fa fa-check-square-o text-primary mr-2"></i>
            {{f.middle | titlecase}}
          </li>
        </ul>
      </div>
      <div class="col-lg-4 col-md-4">
        <ul class="list-group">
          <li *ngFor="let f of SERVICES" class="pl-0 pr-0 list-group-item d-flex align-items-center">
            <i class="fa fa-check-square-o text-primary mr-2"></i>
            {{f.right | titlecase}}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
