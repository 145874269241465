<app-nav-bar [svg]="true"></app-nav-bar>
<app-bread-crumb title="Contactos"></app-bread-crumb>
<div class="contact-area svg_bg" id="featured">
  <div class="container">
    <div class="row">
      <div class="col-md-4 wow fadeInLeft">
        <div class="contact-left">
          <a href="http://wa.me/18294444545"
             target="_blank"
             class="contact-left-single text-center first">
            <img src="assets/img/whatsapp.png" alt="">
            <h4>
              24/7 chat en vivo
            </h4>
          </a>
          <a href="tel:8294444545"
             target="_blank"
             class="contact-left-single second text-center">
            <img src="assets/img/phone.png" alt="">
            <h4>+1(829) 444-4545</h4>
          </a>
          <a href="mailto:info@rodal.tech"
             target="_blank"
             class="contact-left-single text-center">
            <img src="assets/img/mail.png" alt="">
            <h4>info@rodal.tech</h4>
          </a>
        </div>
      </div>
      <div class="col-md-8 wow fadeInRight">
        <div class="blog_details_single6 _cta">
          <h4>Ponte en contacto con nosotros</h4>
          <form [formGroup]="contactForm"
                (submit)="submit($event)"
                id="contact-form">
            <input formControlName="name"
                   title="Nombre"
                   type="text" name="name"
                   placeholder="Nombre Completo">
            <input formControlName="email"
                   type="email"
                   name="email"
                   title="Correo Electrónico"
                   placeholder="Correo Electrónico">
            <input formControlName="phone"
                   mask="(000) 000-0000"
                   type="text"
                   name="email"
                   title="Teléfono"
                   placeholder="Teléfono">
            <textarea formControlName="message"
                      title="Mensaje"
                      name="message" placeholder="Mensaje">
            </textarea>
            <button class="btn btn-success"
                    [disabled]="contactForm.invalid"
                    type="submit">
              <i *ngIf="processing" class="fa fa-spinner fa-spin"></i>
              Enviar Mensaje
            </button>
          </form>
          <p class="form-message"></p>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
