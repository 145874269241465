import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  readonly contactForm: FormGroup;
  processing = false;

  constructor(formBuilder: FormBuilder,
              private toasterService: ToastrService,
              private httpService: HttpClient) {
    this.contactForm = formBuilder.group({
      name: ['', Validators.required],
      business: ['GENERIC', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  submit(event: Event) {
    event.preventDefault();
    this.processing = true;
    this.httpService.post('/message', this.contactForm.getRawValue())
      .subscribe(() => {
        this.processing = false;
        this.contactForm.reset();
        this.toasterService.success('Favor revise su correo con los detalles enviados.', 'Mensaje Enviado');
      }, () => {
        this.processing = false;
        this.toasterService.error('No se ha podido enviar el mensaje.', 'Algo ha salido mal');
      });
  }

}
