<div class="header-area" [ngClass]="{svg_bg: svg}">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <div class="logo">
          <a style="white-space: nowrap" routerLink="/">
            <img style="margin-right: 5px" src="assets/img/logo.png" alt="" class="logo-img">
            <span class="logo-text">Rodaltech, SRL</span>
          </a>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <nav class="navbar navbar-expand-lg navbar-dark mobile-only">
          <a class="navbar-brand"></a>
          <button class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item">
                <a class="nav-link"
                   routerLink="/"
                   [ngClass]="{active: isRouteActive('/', true)}">
                  Inicio
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link"
                   [ngClass]="{active: isRouteActive('/contacts')}"
                   routerLink="/contacts">
                  Contactos
                </a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle"
                   [ngClass]="{active: isRouteActive('/projects')}"
                   id="navbarDropdown"
                   role="button" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false">
                  Productos
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" routerLink="/projects/aisec" routerLinkActive="active">AiSec</a>
                  <a class="dropdown-item" routerLink="/projects/ics-plus" routerLinkActive="active">ICS Plus</a>
                  <a class="dropdown-item" routerLink="/projects/fact-e" routerLinkActive="active">Fact-E</a>
                  <a class="dropdown-item" routerLink="/projects/gaccsis" routerLinkActive="active">Gaccsis</a>
                  <a class="dropdown-item" routerLink="/projects/rodalpos" routerLinkActive="active">RodalPOS</a>
                </div>
              </li>
              <li class="nav-item">
                <a class="nav-link"
                   [ngClass]="{active: isRouteActive('/services')}"
                   routerLink="/services">
                  Servicios
                </a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle"
                   [ngClass]="{active: isRouteActive('/social')}"
                   role="button" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false">
                  Redes
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" href="//instagram.com/rodaltech" target="_blank">Instagram</a>
                  <a class="dropdown-item" href="//web.facebook.com/rodaltech?_rdc=1&_rdr" target="_blank">Facebook</a>
                  <a class="dropdown-item" href="//twitter.com/rodaltech" target="_blank">Twitter</a>
                  <a class="dropdown-item" href="//www.linkedin.com/company/rodaltech/" target="_blank">LinkedIn</a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
        <div class="mainmenu-area pc-only">
          <ul>
            <li>
              <a [ngClass]="{active: isRouteActive('/', true)}"
                 routerLink="/">Inicio</a>
            </li>
            <li>
              <a [ngClass]="{active: isRouteActive('/contacts')}"
                 routerLink="/contacts">Contactos
              </a>
            </li>
            <li>
              <a [ngClass]="{active: isRouteActive('/projects')}"
                 routerLink="/projects">Productos</a>
              <ul class="drop_menu">
                <li>
                  <a routerLink="/projects/aisec" class="justify-content-center">
                    <img src="assets/img/projects/aisec.png" alt="">
                    AiSec
                  </a>
                </li>
                <li>
                  <a routerLink="/projects/ics-plus">
                    <img src="assets/img/projects/ics_plus.png" alt="Ics-plus">
                    ICS Plus
                  </a>
                </li>
                <li>
                  <a routerLink="/projects/gaccsis">
                    <img src="assets/img/projects/gaccsis.png" alt="Gaccsis">
                    Gaccsis
                  </a>
                </li>
                <li>
                  <a routerLink="/projects/rodalpos">
                    <img src="assets/img/projects/rodalpos.png" alt="Rodalpos">
                    RodalPOS
                  </a>
                </li>
                <li>
                  <a routerLink="/projects/fact-e" class="justify-content-center">
                    <img src="assets/img/projects/Facte.png" alt="Fact-e">
                    Fact-E
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a [ngClass]="{active: isRouteActive('/services')}"
                 routerLink="/services">Servicios</a>
            </li>
            <li>
              <a [ngClass]="{active: isRouteActive('/social')}">Redes</a>
              <ul class="drop_menu">
                <li>
                  <a href="//instagram.com/rodaltech" target="_blank">
                    <i class="fa-fw fa fa-instagram"></i>
                    Instagram
                  </a>
                </li>
                <li>
                  <a href="//web.facebook.com/rodaltech?_rdc=1&_rdr" target="_blank">
                    <i class="fa-fw fa fa-facebook"></i>
                    Facebook
                  </a>
                </li>
                <li>
                  <a href="//twitter.com/rodaltech" target="_blank">
                    <i class="fa-fw fa fa-twitter"></i>
                    Twitter
                  </a>
                </li>
                <li>
                  <a href="//www.linkedin.com/company/rodaltech/" target="_blank">
                    <i class="fa-fw fa fa-linkedin"></i>
                    LinkedIn
                  </a>
                </li>
                <!--                <li>-->
                <!--                  <a routerLink="/social/blog">-->
                <!--                    <i class="fa fa-blog"></i>-->
                <!--                    Nuestra columna-->
                <!--                  </a>-->
                <!--                </li>-->
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-3.5">
        <div class="header-right">
          <a class="login" routerLink="/projects/aisec" routerLinkActive="active">AiSec</a>
          <a class="login global_gradient_btn" routerLinkActive="active" routerLink="/projects/ics-plus">ICS Plus</a>
          <a class="login" routerLink="/projects/fact-e" routerLinkActive="active">Fact-E</a>
        </div>
      </div>
    </div>
  </div>
</div>
