import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  @Input()
  svg = false;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    console.log($('.slicknav_menu'));
    // if ($.fn.slicknav) {
    $('.mainmenu-area ul, .home2_menu_right ul').slicknav({
      prependTo: '.responsive_menu',
      label: ''
    });
    // }
  }


  isRouteActive(routes: string, equals: boolean = false) {
    if (equals) {
      return this.router.url === routes;
    }
    return this.router.url.includes(routes);
  }
}
