import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from './modules/shared/shared.module';
import {HomeComponent} from './components/home/home.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ContactComponent} from './components/contact/contact.component';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxMaskModule} from 'ngx-mask';
import {HttpClientModule} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import { ServicesComponent } from './components/services/services.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { MapNavigationComponent } from './components/map-navigation/map-navigation.component';
import {AgmCoreModule} from "@agm/core";


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactComponent,
    ServicesComponent,
    PrivacyPolicyComponent,
    MapNavigationComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      closeButton: true,
      enableHtml: true,
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
    }),
    NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAUhAmRLbmMtMxcO4Zw41VxWtqFcflkh8o'
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
