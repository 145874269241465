
<body>
<div class="container" style="margin-top: 20px; margin-bottom: 60px">
  <div class="col col-md-12">
    <h2><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Política de privacidad</span></span>
    </h2>

    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Última actualización: 22 de
            julio de 2020</span></span></p>

    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Esta Política de privacidad
            describe nuestras políticas y procedimientos sobre la recopilación, uso y divulgación de su información
            cuando utiliza el Servicio y le informa sobre sus derechos de privacidad y cómo la ley lo
            protege.</span></span></p>

    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Utilizamos sus datos personales
            para proporcionar y mejorar el Servicio. </span><span style="vertical-align: inherit;">Al usar el Servicio,
            usted acepta la recopilación y el uso de información de acuerdo con esta Política de
            privacidad. </span><span style="vertical-align: inherit;">Esta Política de privacidad es mantenida por
            el </span></span><a href="https://www.privacypolicies.com/privacy-policy-generator/"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Generador de políticas de
            privacidad</span></span></a><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">
            .</span></span></p>

    <h2><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Interpretación y
            Definiciones</span></span></h2>
    <h5><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Interpretación</span></span>
    </h5>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Las palabras cuya letra inicial
            está en mayúscula tienen significados definidos en las siguientes condiciones.</span></span></p>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Las siguientes definiciones
            tendrán el mismo significado independientemente de si aparecen en singular o en plural.</span></span></p>

    <h5><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Definiciones</span></span>
    </h5>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">A los fines de esta Política de
            privacidad:</span></span></p>
    <ul>
      <li>
        <p><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Usted se</span></span></strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;"> refiere a la persona
                    que accede o utiliza el Servicio, o la compañía u otra entidad legal en nombre de la cual dicha
                    persona accede o utiliza el Servicio, según corresponda.</span></span></p>
      </li>
      <li>
        <p><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Compañía</span></span></strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;"> (referida como "la
                    Compañía", "Nosotros", "Nosotros" o "Nuestro" en este Acuerdo) se refiere a Rodaltech, SRL, Calle
                    6ta, No. 1, Residencial Oasis, Autopista de San Isisdro. </span><span style="vertical-align: inherit;">Santo Domingo Este, República Dominicana ..</span></span></p>
      </li>
      <li>
        <p><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Afiliado</span></span></strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;"> significa una entidad que
                controla, está controlada o está bajo el control común de una parte, donde "control" significa la
                propiedad del 50% o más de las acciones, intereses patrimoniales u otros valores con derecho a voto para
                la elección de directores u otra autoridad administrativa .
            </span></span></p>
      </li>
      <li>
        <p><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Cuenta</span></span></strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;"> significa una cuenta única
                creada para que Usted acceda a nuestro Servicio o partes de nuestro Servicio.
            </span></span></p>
      </li>
      <li>
        <p><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">El sitio web
                se</span></span></strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">
          refiere a AISec, accesible desde https://rodal.tech/aisec</span></span></p>
      </li>
      <li>
        <p><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">El servicio
                se</span></span></strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">
                refiere al sitio web.</span></span></p>
      </li>
      <li>
        <p><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">País
                se</span></span></strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">
                refiere a: República Dominicana</span></span></p>
      </li>
      <li>
        <p><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Proveedor de
                    servicios se</span></span></strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;"> refiere a cualquier persona física o jurídica que procese los
                    datos en nombre de la Compañía. </span><span style="vertical-align: inherit;">Se refiere a compañías
                    de terceros o individuos empleados por la Compañía para facilitar el Servicio, proporcionar el
                    Servicio en nombre de la Compañía, realizar servicios relacionados con el Servicio o ayudar a la
                    Compañía a analizar cómo se utiliza el Servicio.</span></span></p>
      </li>
      <li>
        <p><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">El Servicio de
                medios sociales de terceros se</span></span></strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;"> refiere a cualquier sitio web o cualquier sitio web de red social
                a través del cual un Usuario puede iniciar sesión o crear una cuenta para usar el Servicio.
            </span></span></p>
      </li>
      <li>
        <p><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Los datos
                    personales</span></span></strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;"> son cualquier información relacionada con un individuo
                    identificado o identificable.
                </span></span></p>
      </li>
      <li>
        <p><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Las cookies</span></span></strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;"> son pequeños archivos que
                un sitio web coloca en su computadora, dispositivo móvil o cualquier otro dispositivo y que contienen
                los detalles de su historial de navegación en ese sitio web entre sus usos.
            </span></span></p>
      </li>
      <li>
        <p><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Dispositivo</span></span></strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;"> significa cualquier
                dispositivo que pueda acceder al Servicio, como una computadora, un teléfono celular o una tableta
                digital.
            </span></span></p>
      </li>
      <li>
        <p><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Los Datos de uso
                se</span></span></strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">
                refieren a los datos recopilados automáticamente, ya sea generados por el uso del Servicio o desde la
                propia infraestructura del Servicio (por ejemplo, la duración dentro de la aplicación).
            </span></span></p>
      </li>
    </ul>

    <h2><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Recopilación y uso de sus
            datos personales</span></span></h2>
    <h5><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Tipos de datos
            recopilados</span></span></h5>

    <h5><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Información
            personal</span></span></h5>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Al utilizar nuestro servicio, podemos pedirle que nos proporcione cierta información de identificación personal que se puede utilizar para contactarlo o identificarlo. </span><span
      style="vertical-align: inherit;">La información de identificación personal puede incluir, entre otros:</span></span></p>

    <ul>
      <li><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Dirección de correo electrónico</span></span></li>
      <li><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Nombre y apellido</span></span></li>
      <li><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Número de teléfono</span></span></li>
      <li><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Dirección, estado, provincia, código postal, ciudad</span></span></li>
    </ul>


    <h3 style="margin-top:20px"><span style="vertical-align: inherit;"><span style= "vertical-align: inherit;">Datos de uso</span></span></h3>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Los Datos de uso se recopilan automáticamente cuando se utiliza el Servicio.</span></span></p>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Los datos de uso pueden incluir información como la dirección de protocolo de Internet de su dispositivo (por ejemplo, dirección IP), el tipo de navegador, la versión del navegador, la hora y la fecha de su visita, el tiempo dedicado a esas páginas, un dispositivo único identificadores y otros datos de diagnóstico.</span></span></p>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Cuando accede al Servicio a través de un dispositivo móvil oa través de él, podemos recopilar cierta información automáticamente, que incluye, entre otros, el tipo de dispositivo móvil que utiliza, la identificación única de su dispositivo móvil, la dirección IP de su dispositivo móvil, su teléfono móvil sistema operativo, el tipo de navegador de Internet móvil que utiliza, identificadores de dispositivo únicos y otros datos de diagnóstico.</span></span></p>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">También podemos recopilar información que su navegador envía cada vez que visita nuestro Servicio o cuando accede al Servicio a través de un dispositivo móvil.</span></span></p>


    <h3><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Tecnologías de seguimiento y cookies</span></span></h3>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Utilizamos cookies y tecnologías de seguimiento similares para rastrear la actividad en nuestro servicio y almacenar cierta información. </span><span
      style="vertical-align: inherit;">Las tecnologías de seguimiento utilizadas son balizas, etiquetas y scripts para recopilar y rastrear información y para mejorar y analizar Nuestro Servicio.</span></span></p>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Puede indicar a su navegador que rechace todas las cookies o que indique cuándo se envía una cookie. </span><span
      style="vertical-align: inherit;">Sin embargo, si no acepta Cookies, es posible que no pueda usar algunas partes de nuestro Servicio.</span></span></p>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Las cookies pueden ser cookies "persistentes" o "de sesión". </span><span
      style="vertical-align: inherit;">Las cookies persistentes permanecen en su computadora personal o dispositivo móvil cuando se desconecta, mientras que las cookies de sesión se eliminan tan pronto como cierra su navegador web. </span><span
      style="vertical-align: inherit;">Obtenga más información sobre las cookies en el artículo </span></span><a href="https://www.privacypolicies.com/blog/cookies/"><span
      style="vertical-align: inherit;"><span
      style="vertical-align: inherit;">"Qué son las cookies"</span></span></a><span style="vertical-align: inherit;"><span
      style="vertical-align: inherit;"> .
        </span></span></p>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Utilizamos cookies de sesión y persistentes para los fines que se detallan a continuación:</span></span></p>
    <ul>
      <li>
        <p><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Cookies necesarias / esenciales</span></span></strong>
        </p>
        <p><span style="vertical-align: inherit;"><span
          style="vertical-align: inherit;">Tipo: Cookies de sesión</span></span></p>
        <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Administrado por: nosotros</span></span></p>
        <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Objetivo: estas cookies son esenciales para proporcionarle servicios disponibles a través del sitio web y permitirle utilizar algunas de sus funciones. </span><span
          style="vertical-align: inherit;">Ayudan a autenticar a los usuarios y a evitar el uso fraudulento de cuentas de usuario. </span><span
          style="vertical-align: inherit;">Sin estas cookies, los servicios que ha solicitado no se pueden proporcionar, y solo usamos estas cookies para proporcionarle dichos servicios.</span></span></p>
      </li>
      <li>
        <p><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Política de cookies / Aceptación de avisos Cookies</span></span></strong></p>
        <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Tipo: Cookies persistentes</span></span></p>
        <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Administrado por: nosotros</span></span></p>
        <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Propósito: Estas cookies identifican si los usuarios han aceptado el uso de cookies en el sitio web.</span></span></p>
      </li>
      <li>
        <p><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Cookies de funcionalidad</span></span></strong></p>
        <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Tipo: Cookies persistentes</span></span></p>
        <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Administrado por: nosotros</span></span></p>
        <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Propósito: estas cookies nos permiten recordar las elecciones que usted hace cuando utiliza el sitio web, como recordar sus datos de inicio de sesión o preferencia de idioma. </span><span
          style="vertical-align: inherit;">El propósito de estas Cookies es proporcionarle una experiencia más personal y evitar que tenga que volver a ingresar sus preferencias cada vez que utilice el sitio web.</span></span></p>
      </li>
    </ul>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Para obtener más información sobre las cookies que utilizamos y sus opciones con respecto a las cookies, visite nuestra Política de cookies.</span></span></p>

    <h2><span style="vertical-align: inherit;"><span
      style="vertical-align: inherit;">Uso de sus datos personales</span></span></h2>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">La Compañía puede usar Datos personales para los siguientes propósitos:</span></span></p>


    <ol style="padding-left: 20px">
      <li><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Para proporcionar y mantener nuestro Servicio</span></span></strong><span
        style="vertical-align: inherit;"><span style="vertical-align: inherit;"> , incluso para monitorear el uso de nuestro Servicio.
            </span></span></li>
      <li><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Para administrar su cuenta:</span></span></strong><span
        style="vertical-align: inherit;"><span style="vertical-align: inherit;"> para administrar su registro como usuario del Servicio. </span><span
        style="vertical-align: inherit;">Los datos personales que proporcione pueden darle acceso a diferentes funcionalidades del servicio que están disponibles para usted como usuario registrado.
            </span></span></li>
      <li><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Para la ejecución de un contrato:</span></span></strong><span
        style="vertical-align: inherit;"><span style="vertical-align: inherit;"> el desarrollo, cumplimiento y compromiso del contrato de compra de los productos, artículos o servicios que ha comprado o de cualquier otro contrato con nosotros a través del Servicio.
            </span></span></li>
      <li><strong><span style="vertical-align: inherit;"><span
        style="vertical-align: inherit;">Para contactarlo:</span></span></strong><span style="vertical-align: inherit;"><span
        style="vertical-align: inherit;"> Para contactarlo por correo electrónico, llamadas telefónicas, SMS u otras formas equivalentes de comunicación electrónica, como las notificaciones automáticas de una aplicación móvil con respecto a actualizaciones o comunicaciones informativas relacionadas con las funcionalidades, productos o servicios contratados, incluidas las actualizaciones de seguridad, cuando sea necesario o razonable para su implementación.
            </span></span></li>
      <li><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Para proporcionarle</span></span></strong><span
        style="vertical-align: inherit;"><span style="vertical-align: inherit;"> noticias, ofertas especiales e información general sobre otros bienes, servicios y eventos que ofrecemos que son similares a los que ya compró o solicitó, a menos que haya optado por no recibir dicha información.
            </span></span></li>
      <li><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Para administrar sus solicitudes:</span></span></strong><span
        style="vertical-align: inherit;"><span style="vertical-align: inherit;"> para atender y administrar sus solicitudes a nosotros.</span></span></li>
    </ol>


    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Podemos compartir su información personal en las siguientes situaciones:</span></span></p>

    <ol style="padding-left: 20px">
      <li><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Con los proveedores de servicios:</span></span></strong><span
        style="vertical-align: inherit;"><span style="vertical-align: inherit;"> podemos compartir su información personal con los proveedores de servicios para monitorear y analizar el uso de nuestro servicio, para contactarlo.
            </span></span></li>
      <li><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Para transferencias comerciales:</span></span></strong><span
        style="vertical-align: inherit;"><span style="vertical-align: inherit;"> podemos compartir o transferir su información personal en conexión con, o durante negociaciones de cualquier fusión, venta de activos de la Compañía, financiamiento o adquisición de todo o una parte de nuestro negocio a otra compañía.
            </span></span></li>
      <li><strong><span style="vertical-align: inherit;"><span
        style="vertical-align: inherit;">Con afiliados:</span></span></strong><span style="vertical-align: inherit;"><span
        style="vertical-align: inherit;"> podemos compartir su información con nuestros afiliados, en cuyo caso les exigiremos que cumplan con esta política de privacidad. </span><span
        style="vertical-align: inherit;">Los afiliados incluyen nuestra compañía matriz y cualquier otra subsidiaria, socios de empresas conjuntas u otras compañías que controlamos o que están bajo control común con nosotros.
            </span></span></li>
      <li><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Con socios comerciales:</span></span></strong><span
        style="vertical-align: inherit;"><span style="vertical-align: inherit;"> podemos compartir su información con nuestros socios comerciales para ofrecerle ciertos productos, servicios o promociones.
            </span></span></li>
      <li><strong><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Con otros usuarios:</span></span></strong><span
        style="vertical-align: inherit;"><span style="vertical-align: inherit;"> cuando comparte información personal o interactúa de otra manera en las áreas públicas con otros usuarios, dicha información puede ser vista por todos los usuarios y puede ser distribuida públicamente fuera. </span><span
        style="vertical-align: inherit;">Si interactúa con otros usuarios o se registra a través de un servicio de redes sociales de terceros, sus contactos en el servicio de redes sociales de terceros pueden ver su nombre, perfil, fotos y descripción de su actividad. </span><span
        style="vertical-align: inherit;">Del mismo modo, otros usuarios podrán ver descripciones de su actividad, comunicarse con usted y ver su perfil.
            </span></span></li>
    </ol>

    <h2><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Retención de sus datos personales</span></span></h2>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">La Compañía retendrá Sus Datos personales solo durante el tiempo que sea necesario para los fines establecidos en esta Política de privacidad. </span><span
      style="vertical-align: inherit;">Retendremos y utilizaremos sus Datos personales en la medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a conservar sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestros acuerdos y políticas legales.</span></span></p>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">La Compañía también retendrá los Datos de uso para fines de análisis interno. </span><span
      style="vertical-align: inherit;">Los datos de uso generalmente se conservan por un período de tiempo más corto, excepto cuando estos datos se utilizan para fortalecer la seguridad o mejorar la funcionalidad de nuestro servicio, o estamos legalmente obligados a retener estos datos por períodos de tiempo más largos.</span></span></p>

    <h2><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Transferencia de sus datos personales</span></span></h2>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Su información, incluidos los Datos personales, se procesa en las oficinas operativas de la Compañía y en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. </span><span
      style="vertical-align: inherit;">Significa que esta información puede transferirse a computadoras ubicadas fuera de su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de su jurisdicción.</span></span></p>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Su consentimiento a esta Política de privacidad seguido de Su envío de dicha información representa Su aceptación de esa transferencia.</span></span></p>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">La Compañía tomará todas las medidas razonablemente necesarias para garantizar que sus datos se traten de forma segura y de acuerdo con esta Política de privacidad y no se realizará ninguna transferencia de sus datos personales a una organización o país, a menos que existan controles adecuados, incluida la seguridad de Sus datos y otra información personal.</span></span></p>

    <h2><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Divulgación de sus datos personales</span></span></h2>
    <h3><span style="vertical-align: inherit;"><span
      style="vertical-align: inherit;">Transacciones de negocios</span></span></h3>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Si la Compañía está involucrada en una fusión, adquisición o venta de activos, sus Datos personales pueden ser transferidos. </span><span
      style="vertical-align: inherit;">Le enviaremos un aviso antes de que sus Datos personales sean transferidos y estén sujetos a una Política de privacidad diferente.</span></span></p>
    <h3><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Cumplimiento de la ley</span></span></h3>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Bajo ciertas circunstancias, se le puede solicitar a la Compañía que revele Sus Datos personales si así lo exige la ley o en respuesta a solicitudes válidas de las autoridades públicas (por ejemplo, un tribunal o una agencia gubernamental).</span></span></p>
    <h3><span style="vertical-align: inherit;"><span
      style="vertical-align: inherit;">Otros requisitos legales</span></span></h3>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">La Compañía puede divulgar Sus Datos personales en la creencia de buena fe de que dicha acción es necesaria para:</span></span></p>

    <ol style="padding-left: 20px">
    <li><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Cumplir con una obligación legal</span></span></li>
    <li><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Proteger y defender los derechos o propiedad de la Compañía.</span></span></li>
    <li><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Prevenir o investigar posibles irregularidades en relación con el Servicio.</span></span></li>
    <li><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Proteger la seguridad personal de los Usuarios del Servicio o del público.</span></span></li>
    <li><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Proteger contra responsabilidad legal</span></span></li>
    </ol>

    <br><br>
    <h2><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Seguridad de sus datos personales</span></span></h2>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">La seguridad de sus datos personales es importante para nosotros, pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro. </span><span
      style="vertical-align: inherit;">Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger sus datos personales, no podemos garantizar su seguridad absoluta.</span></span></p>

    <h2><span style="vertical-align: inherit;"><span
      style="vertical-align: inherit;">Privacidad infantil</span></span></h2>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Nuestro servicio no se dirige a ninguna persona menor de 18 años. No recopilamos a sabiendas información de identificación personal de ninguna persona menor de 18 años. Si usted es padre o tutor y sabe que su hijo nos ha proporcionado datos personales, por favor Contáctenos. </span><span
      style="vertical-align: inherit;">Si nos damos cuenta de que hemos recopilado datos personales de cualquier persona menor de 18 años sin verificación del consentimiento de los padres, tomamos medidas para eliminar esa información de nuestros servidores.</span></span></p>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Si necesitamos confiar en el consentimiento como base legal para procesar su información y su país requiere el consentimiento de un padre, es posible que solicitemos el consentimiento de sus padres antes de recopilar y usar esa información.</span></span></p>

    <h2><span style="vertical-align: inherit;"><span
      style="vertical-align: inherit;">Enlaces a otros sitios web</span></span></h2>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Nuestro servicio puede contener enlaces a otros sitios web que no son operados por nosotros. </span><span
      style="vertical-align: inherit;">Si hace clic en un enlace de un tercero, se lo dirigirá al sitio de ese tercero. </span><span
      style="vertical-align: inherit;">Le recomendamos encarecidamente que revise la Política de privacidad de cada sitio que visite.</span></span></p>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de sitios o servicios de terceros.</span></span></p>

    <h2><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Cambios a esta Política de privacidad</span></span></h2>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Podemos actualizar nuestra Política de privacidad de vez en cuando. </span><span
      style="vertical-align: inherit;">Le notificaremos cualquier cambio publicando la nueva Política de privacidad en esta aplicación.</span></span></p>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Le informaremos por correo
            electrónico y / o un aviso destacado sobre Nuestro Servicio, antes de que el cambio entre en vigencia y
            actualizaremos la fecha de "Última actualización" en la parte superior de esta Política de
            privacidad.</span></span></p>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Se le recomienda que revise
            esta Política de privacidad periódicamente para detectar cualquier cambio. </span><span style="vertical-align: inherit;">Los cambios a esta Política de privacidad son efectivos cuando se
            publican en esta aplicación.</span></span></p>

    <h3><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Contáctenos</span></span></h3>
    <p><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Si tiene alguna pregunta sobre
            esta Política de privacidad, puede contactarnos:</span></span></p>

    <ul style="list-style-type: disc">
      <li><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Por correo electrónico:
          info@rodal.tech</span></span></li>
      <li><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Al visitar nuestro sitio web:
                https://rodal.tech/info</span></span></li>
      <li><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Vía telefónica, al :
                +18294444545</span></span></li>
    </ul>
  </div>
</div>
</body>

