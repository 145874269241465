import {Component} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent, RouterOutlet} from '@angular/router';
import {routeTransitionAnimations} from './route-transition-animations';
import {filter} from 'rxjs/operators';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeTransitionAnimations]
})
export class AppComponent {
  title = 'front-end';


  constructor(private router: Router,
              private metaService: Meta,
              private titleService: Title,
              private route: ActivatedRoute) {
    router.events
      .pipe(filter(r => {
        return r instanceof NavigationEnd;
      }))
      .subscribe((event: RouterEvent) => {
        const docCover = this.lastChild.snapshot.data.docCover;
        const docTitle = this.lastChild.snapshot.data.docTitle;
        const docDescription = this.lastChild.snapshot.data.docDescription;
        const title = this.docTitle(docTitle);
        this.titleService.setTitle(title);
        this.metaService.updateTag({property: 'og:image', content: this.docCover(docCover)});
        this.metaService.updateTag({property: 'og:type', content: 'website'});
        this.metaService.updateTag({property: 'og:description', content: this.docDescription(docDescription)});
        this.metaService.updateTag({property: 'og:url', content: 'https://rodal.tech' + event.url});
        this.metaService.updateTag({property: 'og:title', content: title});
        this.metaService.updateTag({name: 'twitter:url', content: 'https://rodal.tech' + event.url});
        this.metaService.updateTag({name: 'twitter:description', content: this.docDescription(docDescription)});
      });
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData.animationState;
  }

  get lastChild() {
    let lastChild = this.route;
    while (lastChild.firstChild) {
      if (lastChild.firstChild) {
        lastChild = lastChild.firstChild;
      }
    }
    return lastChild;
  }

  docCover(docCover): string {
    const rodaltechSRL = 'https://rodal.tech/assets/img/rodaltech.png';
    if (docCover) {
      return docCover;
    }
    return rodaltechSRL;
  }

  docTitle(docTitle): string {
    const rodaltechSRL = 'Rodaltech, SRL';
    if (docTitle) {
      return rodaltechSRL + ' - ' + docTitle;
    }
    return rodaltechSRL;
  }

  docDescription(docDescription): string {
    const rodaltechSRL = '¡Seguimos innovando!';
    if (docDescription) {
      return docDescription;
    }
    return rodaltechSRL;
  }
}
