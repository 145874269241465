import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import {RouterModule} from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { BreadCrumbComponent } from './components/bread-crumb/bread-crumb.component';



@NgModule({
    declarations: [NavBarComponent, FooterComponent, BreadCrumbComponent],
    exports: [
        NavBarComponent,
        FooterComponent,
        BreadCrumbComponent
    ],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class SharedModule { }
