import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {ContactComponent} from './components/contact/contact.component';
import {ServicesComponent} from "./components/services/services.component";
import {PrivacyPolicyComponent} from "./components/privacy-policy/privacy-policy.component";
import {MapNavigationComponent} from "./components/map-navigation/map-navigation.component";


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      animationState: 'One',
      docTitle: 'Inicio',
    }
  },
  {
    path: 'contacts',
    component: ContactComponent,
    data: {
      animationState: 'Two',
      docTitle: 'Contactos'
    }
  },
  {
    path: 'info',
    redirectTo: 'contacts',
    data: {
      animationState: 'Two',
      docTitle: 'Inicio',
    }
  },
  {
    path: 'projects',
    loadChildren: () => import('./modules/projects/projects.module').then(m => m.ProjectsModule)
  },
  {
    path: 'services',
    component: ServicesComponent,
    data: {
      animationState: 'Four',
      docTitle: 'Servicios',
    }
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {
      animationState: 'Five',
      docTitle: 'Politicas De Privacidad',
    }
  },
  {
    path: 'aisec',
    redirectTo: 'projects/aisec'
  },
  {
    path: 'ics-plus',
    redirectTo: 'projects/ics-plus'
  },
  {
    path: 'facte',
    redirectTo: 'projects/fact-e'
  },
  {
    path: 'icsplus',
    redirectTo: 'projects/ics-plus'
  },
  {
    path: 'maps/:location',
    component: MapNavigationComponent
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
