import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-map-navigation',
  templateUrl: './map-navigation.component.html',
  styleUrls: ['./map-navigation.component.scss']
})
export class MapNavigationComponent implements OnInit {
  latitude: number;
  longitude: number;
  zoom: number;

  constructor(private route: ActivatedRoute,
              private router: Router) {
    const location = route.snapshot.params.location;
    const params = location.replace("@", '').replace('z', '').split(',');
    console.log(params);
    if (params.length === 3) {
      this.latitude = parseFloat(params[0]);
      this.longitude = parseFloat(params[1]);
      this.zoom = parseFloat(params[2]);
    }
  }

  ngOnInit(): void {
  }

  setParams($event: number) {
    this.zoom = $event;
    const params = `@${this.latitude},${this.longitude},${this.zoom}z`;
    this.router.navigate(['maps/', params]);

  }
}
