<!--  page loader end -->
<!--  header area start -->
<div class="wraper-area" >
  <div class="left-right">
  <img src="assets/img/icons/store-left.png" style="position: absolute; top:15vh; left: 0 " alt="">
  <img src="assets/img/icons/store-right.png" style="position: absolute; top:15vh; right: 0" alt="">
  </div>
  <app-nav-bar></app-nav-bar>
  <!--  header area end -->
  <!--  hero area start -->
  <div class="hero-area">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <h1>Soluciones a tus necesidades</h1>
          <p>¡Creamos soluciones inteligentes, con la tecnología más novedosa del mercado
            <br> y a la medida de tus necesidades!</p>
          <a routerLink="/contacts" class="hero-btn">
            Contáctanos ahora
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="vectormap_area wow fadeInUp pc-only">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="home2_section_title">
            <h2>Servidores virtualizados en todo el mundo</h2>
            <span class="section_border"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-10 offset-lg-1 col-md-12 mt-3">
          <div class="box-body-map">
            <ul>
              <li><span>Silicon valley</span></li>
              <li><span>New york</span></li>
              <li><span>Sao paulo</span></li>
              <li><span>London</span></li>
              <li><span>Johannesburg</span></li>
              <li><span>Mumbai</span></li>
              <li><span>Singapore</span></li>
              <li><span>Tokyo</span></li>
              <li><span>Sydney</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--  hero area end -->
<!--  service area start -->
<div class="service-area wow fadeInUp" id="featured">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <div class="section-title">
          <h2>¿Por qué elegirnos?</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="single-service">
          <span class="service-icon"><img src="assets/img/icons/performance.png" alt=""></span>
          <h3>Rendimiento</h3>
          <p>
            Diseñamos sistemas optimizados para brindar un alto rendimiento y la mejor experiencia de usuario.
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="single-service cta_pd">
          <span class="service-icon"><img src="assets/img/icons/uptime.png" alt=""></span>
          <h3>Disponibilidad</h3>
          <p>Todas nuestras soluciones son alojadas en la nube, para brindar la más alta disponibilidad del sistema.</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="single-service cta_pds">
          <span class="service-icon"><img src="assets/img/icons/security.png" alt=""></span>
          <h3>Seguridad</h3>
          <p>
            Nuestros sistemas son implementados con los más novedosos protocolos de seguridad existentes.
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="single-service no_pd21">
          <span class="service-icon"><img src="assets/img/icons/support.png" alt=""></span>
          <h3>Soporte</h3>
          <p>
            Contamos con un equipo de ingenieros capacitados para atender cualquier inquietud, a la mayor brevedad
            posible.
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="single-service no_pd21 cta_pd">
          <span class="service-icon"><img src="assets/img/icons/updates.png" alt=""></span>
          <h3>Actualización</h3>
          <p>
            Realizamos actualizaciones continuas en nuestros sistemas, asegurando la estabilidad y seguridad del mismo.
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="single-service no_pd21 cta_pds">
          <span class="service-icon"><img src="assets/img/icons/backup.png" alt=""></span>
          <h3>Respaldo</h3>
          <p>
            Los datos almacenados en nuestros sistemas se encuentran respaldados, garantizando su disponibilidad 24/7.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!--  service area end -->
<!--  hosting area start -->
<div class="hosting-plan-area ctas21">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <div class="section-title">
          <h2>Nuestros Productos</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-10 offset-lg-1 projects">
        <div class="row">
          <!--          <div class="col-md-4 fadeInUp text-center">-->
          <!--            <div class="hosting-single">-->
          <!--              <img src="assets/img/projects/ics_plus.png" alt="ICS-plus">-->
          <!--              <h4>ICS-Plus  </h4>-->
          <!--              <p>-->
          <!--                Sistema ideal para gestionar las ventas de pólizas de seguro. Está orientado, a-->
          <!--                empresas corredoras de pólizas, de una, o múltiples compañías aseguradoras a la vez.-->
          <!--              </p>-->
          <!--              <a routerLink="/projects/icsplus">Saber más</a>-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="col-md-4 text-center wow fadeInRightBig">
            <div class="hosting-single">
              <img src="assets/img/projects/aisec.png" alt="AiSec">
              <h4>APP AiSec</h4>
              <p>
                Aplicación móvil diseñada para mantener el control de la jornada laboral de los colaboradores, en tiempo
                real, utilizando mecanismos de seguridad biométrica avanzado.
              </p>
              <a routerLink="/projects/aisec">Saber más</a>
            </div>
          </div>
          <div class="col-md-4 text-center wow fadeInDownBig">
            <div class="hosting-single">
              <img src="assets/img/projects/gaccsis.png" alt="Gaccsis">
              <h4>APP Gaccsis</h4>
              <p>
                Aplicación móvil diseñada para gestionar y controlar en tiempo real, los distintos accesos realizados
                por los residentes, visitantes y empleados, en proyectos residenciales.
              </p>
              <a routerLink="/projects/gaccsis">Saber más</a>
            </div>
          </div>

          <div class="col-md-4 text-center wow fadeInLeftBig">
            <div class="hosting-single">
              <img src="assets/img/projects/color_logo_Fact-E.png" alt="Fact-E">
              <h4>Fact-E</h4>
              <p>
                Sistema web diseñado para emitir, firmar y recibir comprobantes
                fiscales de manera electrónica, el cual,
                cumple con las normativas tributarias establecidas en la República Dominicana.
              </p>
              <a routerLink="/projects/fact-e">Saber más</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--  hosting area end -->
<!--  cpanel area start -->
<!--  cpanel area end -->
<!--  pricing area start -->
<!--  pricing area end -->
<!--  powering area start -->
<div class="powering-area wow fadeInUp">
  <div class="container">
    <div class="row">
      <div class="col-md-5">
        <div class="powering-left">
          <h2>Clientes</h2>
          <p>
            Brindar a nuestros clientes un servicio <b>profesional</b>, de alta <b>calidad</b> y <b>novedoso</b>,
            es nuestra prioridad. Estamos <b>comprometidos</b> con desarrollar sistemas, con los mejores estándares de
            <b>calidad,</b> dándole siempre a nuestros clientes la <b>seguridad</b> de recibir un producto a la medida
            de sus necesidades.
          </p>
        </div>
      </div>
      <div class="col-md-7 text-right">
        <div class="powering-logo-right">
          <span>
            <a href="https://www.superbravo.com.do/" target="_blank" rel=noopener>
              <img src="assets/img/customers/Bravo.png" alt="Supermercados Bravo">
            </a>
          </span>
          <span>
            <a href="https://plazalama.com.do" target="_blank" rel=noopener>
              <img src="assets/img/customers/Plaza_Lama.jpg" alt="Plaza Lama">
            </a>
          </span>
          <br>
          <span>
            <a href="//instagram.com/bordamaxrd" target="_blank" rel=noopener>
              <img src="assets/img/customers/bordamax.jpg" alt="">
              </a>
          </span>
          <span>
            <a href="//instagram.com/gabrielyasociados" target="_blank" rel=noopener>
              <img src="assets/img/customers/gabriel_asociados.jpg" alt="">
            </a>
          </span>
          <br>
          <span>
            <a href="http://www.guardianesdelaregion.com/" target="_blank" rel=noopener>
              <img src="assets/img/customers/gdlr.png" alt="">
            </a>
          </span>
          <span>
             <img src="assets/img/customers/rv_imperio.png" alt="Imperio Eléctrico">
          </span>
          <span>
            <a href="//instagram.com/jgseguridad" target="_blank" rel=noopener>
            <img src="assets/img/customers/jgseguridad.png" alt="">
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<!--  powering area end -->
<!--  faq area start -->
<!--  faq area end -->
<!--  app area start -->
<div class="app-logo-area wow fadeInUp pc-only">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <div class="section-title">
          <h2>Nuestros productos</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="app-logo">
          <ul>
            <li><img src="assets/img/projects/ics_plus.png" alt="ICS-Plus"></li>
            <li><img src="assets/img/projects/gaccsis.png" alt="Gaccsis"></li>
            <li><img src="assets/img/projects/aisec.png" alt="Aisec"></li>
            <li><img src="assets/img/projects/color_logo_Fact-E.png" alt="Fact-E"></li>
            <li><img src="assets/img/projects/rodalpos.png" alt="Rodalpos"></li>
            <li><img src="assets/img/projects/ics_plus.png" alt=""></li>
            <li><img src="assets/img/projects/gaccsis.png" alt=""></li>
            <li><img src="assets/img/projects/aisec.png" alt=""></li>
            <li><img src="assets/img/projects/color_logo_Fact-E.png" alt="Fact-E"></li>
            <li><img src="assets/img/projects/rodalpos.png" alt=""></li>
            <li><img src="assets/img/projects/ics_plus.png" alt=""></li>
            <li><img src="assets/img/projects/gaccsis.png" alt=""></li>
            <li><img src="assets/img/projects/color_logo_Fact-E.png" alt="Fact-E"></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!--  app area end -->
<!--  support area start -->
<div class="after-pc-only footer-widget-area-bg wow fadeInUp">
  <div class="support-area">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-title">
            <h2>Soporte 24/7</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <a class="pd3"
             routerLink="/contacts">
            Contacto en línea
          </a>
        </div>
        <div class="col-md-4">
          <a class="pd3" target="_blank"
             href="http://wa.me/18294444545">
            24/7 chat en vivo
          </a>
        </div>
        <div class="col-md-4">
          <a class="pd3" href="tel:8294444545">+1(829) 444-4545</a>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
  <!--  support area end -->
  <!--  footer widget area start -->
</div>
<!--  footer widget area end -->
