<div class="footer-widget-area">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="widget-border"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-4">
        <div class="footer-logo">
          <a (click)="scrollToTop($event)" routerLink="/">
            <img class="footer-logo" src="assets/img/logo.png" alt="">
          </a>
        </div>
      </div>
      <div class="col-lg-4 offset-lg-2 col-md-4">
        <div class="widget-list">
          <h4>Rodaltech, SRL</h4>
          <ul>
            <li>
              <a href="tel:8294444545">
                <i class="fa fa-phone-square"></i>
                +1 829 444-4545
              </a>
            </li>
            <li>
              <a href="mailto:info@rodal.tech">
                <i class="fa fa-envelope-o"></i>
                info@rodal.tech
              </a>
            </li>
            <li>
              <a href="https://goo.gl/maps/2risiVQMbjMW7BwAA" target="_blank">
                <i class="fa fa-map-marker"></i>
                Calle Costa Rica No.80, Plaza Forever, <br> Local 2D, 2do Nivel, Santo Domingo Este.
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="widget-right">
          <h4>Síguenos en nuestras redes</h4>
          <a target="_blank" href="//instagram.com/rodaltech"><i class="fa fa-instagram"></i></a>
          <a target="_blank" href="//facebook.com/rodaltech"><i class="fa fa-facebook"></i></a>
          <a target="_blank" href="//twitter.com/rodaltech"><i class="fa fa-twitter"></i></a>
          <a target="_blank" href="//www.linkedin.com/company/rodaltech"><i class="fa fa-linkedin"></i></a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="widget-border cta"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <p>©2018-{{year}} RodalTech, SRL. Todos los derechos reservados.</p>
        <p></p>
      </div>
    </div>
  </div>
</div>
