import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  readonly SERVICES = [
    {left: 'Desarrollo de Software', middle: 'Marketing Digital', right: 'Pentesting Empresarial'},
    {left: 'Almacenamiento en la Nube', middle: 'Creación de Página Web', right: 'Instalación de Controles de Acceso'},
    {left: 'Diseño y Arquitectura de Software', middle: 'Consultoria en Base de Datos', right: 'Instalación de Cámaras de Videovigilancia'},
    {left: 'Consultoria en Software', middle: 'Diseño de Redes Telemáticas', right: 'Sistemas de Gestión de Contenido'},
    {left: 'Consultoria en Mineria de Datos e Inteligencia Artificial', middle: 'Soporte de Servidor y Migración a la Nube', right: 'Outsourcing de Recursos' +
        'especializados en el area De informática'},
        ];

  constructor() { }

  ngOnInit(): void {
  }

}
